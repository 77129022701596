import React from 'react';
import Snowfall from 'react-snowfall';


const SnowBackground: React.FC = () => {
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      background: 'black',
      overflow: 'hidden'
    }}>
      <Snowfall 
        style={{
          background: 'transparent',
          width: '100%',
          height: '100%'
        }}
        color="#ffffff"
        snowflakeCount={100}
        speed={[0, 2]} // Массив значений скорости от 0 до 6
        wind={[2.5, 7]} // Массив значений ветра от 2.5 до 7
        radius={[0.1, 3]} // Массив значений радиуса от 0.5 до 3
      />
    </div>
  );
};

export default SnowBackground;
