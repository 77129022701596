import axios from 'axios';

// export async function getChatGPTResponse(prompt: string): Promise<string> {
//   try {
//     const response = await axios.post('https://v92781.hosted-by-vdsina.com:5000/api/chat', { prompt }); // Убедитесь, что URL правильный
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching data from server:', error);
//     return 'Sorry, there was an error processing your request.';
//   }
// }


export async function getChatGPTResponse(prompt: string): Promise<string> {
  try {
    // Убедитесь, что URL правильный и не содержит порт 5000
    const response = await axios.post('https://v92781.hosted-by-vdsina.com/api/chat', { prompt });
    return response.data;
  } catch (error) {
    console.error('Error fetching data from server:', error);
    return 'Sorry, there was an error processing your request.';
  }
}