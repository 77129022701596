import React, { useState, useRef, useEffect, useMemo } from 'react';
import './term.css';
import { getChatGPTResponse } from './chatgpt';

interface Message {
  text: string;
  isUserInput: boolean;
}

const Terminal: React.FC = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState(0);

  const playlist = useMemo(() => [
    '/1.mp3',
    '/2.mp3',
    '/3.mp3'
  ], []); // Используем useMemo для мемоизации массива

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = playlist[currentTrack];
      audioRef.current.load();
    }
  }, [currentTrack, playlist]); // playlist теперь мемоизирован

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (input.trim() !== '') {
      // Добавляем сообщение пользователя
      setMessages(prev => [...prev, { text: `> ${input}`, isUserInput: true }]);
      
      // Очищаем поле ввода сразу после отправки
      setInput('');
      
      // Ставим фокус обратно на поле ввода
      inputRef.current?.focus();
      
      // Получаем ответ от чат-ассистента
      const response = await getChatGPTResponse(input);
      setMessages(prev => [...prev, { text: response, isUserInput: false }]);
    }
  };

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(error => {
          console.error('Playback prevented:', error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTrackEnd = () => {
    setCurrentTrack((prevTrack) => (prevTrack + 1) % playlist.length);
  };

  const renderMessage = (message: Message, index: number) => {
    return (
      <p key={index} className={message.isUserInput ? 'user-message' : 'terminal-message'}>
        {message.text}
      </p>
    );
  };

  return (
    <div className="terminal-container">
      <div className="container">
        <div className="terminal_toolbar">
          <div className="btn-shine">agent.predict.bar </div>
          <img className="button-hat" src="/santa-hat-test-9.webp" alt="Santa Hat" />
          <div className="audio-controls">
            <button
              className={`play-pause-button ${isPlaying ? 'playing' : ''}`}
              onClick={togglePlayPause}
            >
              {isPlaying ? (
                <div className="pause-icon"></div>
              ) : (
                <div className="play-icon"></div>
              )}
            </button>
          </div>
        </div>
        <audio
          ref={audioRef}
          loop={false}
          onEnded={handleTrackEnd}
        />
        <div className="terminal_body">
          <div className="terminal_promt">
            <span className="terminal_user">agent@predict:</span>
            <span className="terminal_location">~</span>
            <span className="terminal_bling">$</span>
          </div>
          <div className="messages">
            {messages.map(renderMessage)}
          </div>
        </div>
        
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            ref={inputRef}
            className="terminal_input"
            placeholder="Type your message here"
          />
        </form>
      </div>
    </div>
  );
};

export default Terminal;
