//src/app.tsx
import React from 'react';
import Terminal from './terminal';
import SnowBackground from './SnowBackground';

const App: React.FC = () => {
  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <SnowBackground />
      <Terminal />
      
    </div>
  );
};

export default App;
